<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold"
        >Payments ({{ paymentCount }})</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="mr-1" icon @click="exportPayments" v-on="on">
            <v-icon>{{ icons.export }}</v-icon>
          </v-btn>
        </template>
        <span>Export to Excel</span>
      </v-tooltip>
    </v-app-bar>

    <div class="mt-12 mx-4">
      <v-row>
        <v-col>
          <v-text-field
            flat
            hide-details
            solo
            rounded
            clearable
            background-color="grey lighten-3"
            label="Search Tradie ID"
            v-model="filter.search"
            @input="search"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-combobox
                v-model="dates"
                multiple
                solo
                rounded
                chips
                flat
                small-chips
                label="Filter Payment Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                background-color="grey lighten-3"
                clearable
                v-on="on"
                @click:clear="search"
              ></v-combobox>
            </template>
            <v-date-picker
              v-model="dates"
              @change="search"
              range
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(dates)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-select
            flat
            hide-details
            rounded
            solo
            :items="paymentStatuses"
            :item-text="paymentStatuses.text"
            :item-value="paymentStatuses.value"
            clearable
            background-color="grey lighten-3"
            label="Filter Status"
            v-model="payment_status"
            @change="search"
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <div
      class="mx-4"
      id="payment-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <simple-table
        :loading="loading"
        :headers="tableHeaders"
        :collection="payments"
        :sort="sort"
        @headerClicked="sortPayments"
        @actionClicked="updatePaymentStatusToSent"
        :enableDetails="false"
      />
    </div>
    <v-snackbar v-model="snackbar.show" right>
      {{ snackbar.message }}
      <v-btn color="green" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import SimpleTable from '@/components/SimpleTable'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import dayjs from 'dayjs'

export default {
  name: 'PaymentsPage',

  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
    SimpleTable,
  },

  data() {
    return {
      menu: false,
      loading: false,
      showSearch: false,
      showProfile: false,
      selectedProfile: null,

      paymentStatuses: [
        { text: 'SENT', value: 'SENT' },
        { text: 'SENDING', value: 'SENDING' },
        { text: 'ERROR', value: 'PAYMENT_ERROR' },
      ],
      payment_status: '',
      dates: [],

      filter: {
        search: '',
      },

      sort: '-created_at',

      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
      },
      snackbar: {
        show: false,
        message: null,
      },
    }
  },

  watch: {
    sort: function (newSortBy, oldSortBy) {
      this.clearPayments()
      this.fetchPayments()
    },
  },

  created() {
    this.clearPayments()
    this.fetchPayments(1)
  },

  destroyed() {
    this.clearPayments()
  },

  computed: {
    ...mapState({
      payments: (state) => state.payment.list,
      listMeta: (state) => state.payment.listMeta,
    }),

    ...mapGetters('payment', ['paymentCount']),

    paginationLength() {
      return this.listMeta.last_page | 0
    },

    dateSpan() {
      return this.dates && this.dates[1]
        ? this.dates[0] + ',' + this.dates[1]
        : false
    },

    tableHeaders() {
      return [
        { property: 'invoice_number', name: 'Invoice Number' },
        {
          property: 'tradie_id',
          name: 'Tradie ID',
          sortable: false,
        },
        { property: 'paid_at', name: 'Payment Date' },
        { property: 'amount' },
        { property: 'zip_charge' },
        { property: 'tradenow_fee' },
        { property: 'payable' },
        {
          property: 'license_number',
          sortable: false,
        },
        { property: 'status', status: true },
        {
          property: 'tag_as_sent',
          button: true,
          hide_comparator: 'SENT',
          hide_key: 'status',
          key: 'payment_id',
        },
      ]
    },
  },

  methods: {
    ...mapActions({
      getPayments: 'payment/getPayments',
      exportToExcel: 'payment/exportToExcel',
      updatePaymentStatus: 'payment/updatePaymentStatus',
    }),

    ...mapMutations({
      clearPayments: 'payment/clearPaymentList',
    }),

    pageChanged(page) {
      this.fetchPayments(page)
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchPayments(this.listMeta.current_page + 1)
      }
    },

    sortPayments(property) {
      this.sort = (this.sort.startsWith('-') ? '' : '-') + property
    },

    search: debounce(function () {
      this.clearPayments()
      this.fetchPayments()
    }, 600),

    async fetchPayments(page = 1) {
      if (this.loading) return

      const params = {
        page,
        sort: this.sort,
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }
      if (this.dateSpan) {
        params.dateSpan = this.dateSpan
      }

      params.paymentStatus = this.payment_status

      this.loading = true
      await this.getPayments(params)
      this.loading = false
    },

    showProfileDialog(profile) {
      this.selectedProfile = profile
      this.showProfile = true
    },

    async updatePaymentStatusToSent(id, index) {
      if (this.loading) return

      this.loading = true
      await this.updatePaymentStatus({ id: id, status: 'SENT' })
        .then(() => {
          this.payments[index].status = 'SENT'
          this.showSnackbar('Tagged as sent', 'success')
        })
        .catch(() => {
          this.showSnackbar('Ops! Something went wrong', 'red')
        })
      this.loading = false
    },

    async exportPayments() {
      this.loading = true
      await this.exportToExcel()
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `payments${dayjs().format('YYYYMMDD')}.xlsx`
          )
          document.body.appendChild(link)
          link.click()
          this.showSnackbar('File successfully downloaded', 'success')
        })
        .catch(() => {
          this.showSnackbar('Ops! Something went wrong', 'red')
        })
      this.loading = false
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.color = color
      this.snackbar.show = true
    },
  },
}
</script>
